<template>
    <div class="w-full">
        <div class="grid grid-cols-1 lg:grid-cols-12 gap-8 items-center mt-4">
            <div class="lg:col-span-4 text-center">
                <img :src="imageCover" :alt="song.artist.name" :title="song.artist.name" class="rounded-md m-auto w-full"
                    width="490" height="294" loading="eager" />
            </div>

            <div class="lg:col-span-8">
                <div class="grid gap-2 w-full items-center">
                    <span class="font-thin text-nomad-100">{{ song.content_type }}</span>
                    <h1 class="text-white font-bold text-4xl lg:text-5xl">{{ song.name }}</h1>
                    <h2 class="text-xl text-white font-thin">
                        <Link :href="'/artist/' + song.artist.id">{{
                            song.artist.name
                        }}</Link>
                        <featuring :artists="song.featuring" />
                        <div v v-if="song.origin_artist">
                            <h4 class="text-xl text-white font-thin">
                                <Link :href="'/artist/' + song.origin_artist.id">Original vocalist: {{
                                    song.origin_artist.name }}</Link>
                            </h4>
                        </div>
                    </h2>
                    <div class="flex gap-3" >
                        <SmartPlay :song="song" v-if="song.meta && song.meta.music" />
                        <button v-else  aria-label="play song" title="play song"
                            @click="prepareCurrent({ id: song.id, type: 'SONG' })"
                            class="group block-inline text-nomad-900 flex text-left items-center rounded-full gap-2 bg-nomad-accent/80 px-3 py-1 hover:bg-nomad-accent hover:scale-110 transition-all">
                            <span class="icon-play"></span>
                            <div class="grid transition-all">
                                <span class="text-sm">play</span>
                                <span v-if="song.formatted_duration"
                                    class="text-xs -m-1 font-thin  hidden group-hover:block">{{
                                        song.formatted_duration }}</span>
                            </div>
                        </button>

                        

                        <button @click="addToFavorites(song.id)" :class="{
                            'text-nomad-100': !song.is_liked,
                            'text-nomad-accent': song.is_liked,
                        }" title="like" aria-label="like"
                            class="block-inline text-nomad-100 text-2xl rounded-full w-8 h-8 hover:text-nomad-accent icon-heart hover:scale-125 transition-all"></button>

                        <button v-if="song.youtube" @click="openYoutube" aria-label="youtube video" title="youtube video"
                            class="block-inline text-nomad-100 text-3xl rounded-full hover:text-nomad-accent icon-youtube hover:scale-125 transition-all"></button>

                        <button v-if="song.id" @click="copyLink" title="share url" aria-label="share url"
                            class="block-inline text-nomad-100 text-2xl rounded-full hover:text-nomad-accent icon-link hover:scale-125 transition-all"></button>

                        <button @click="addLyrics" title="add lyrics" aria-label="add lyrics"
                            class="block-inline text-nomad-100 text-2xl rounded-full hover:text-nomad-accent icon-file-text hover:scale-125 transition-all"
                            :class="{ 'text-nomad-green': song.verse && song.verse.length && song.verse[0].is_default }"></button>
                        <a :href="'https://www.youtube.com/watch?v=' + song.youtube" class="block-inline text-nomad-100 text-2xl rounded-full hover:text-nomad-red icon-external-link hover:scale-125 transition-all" target="_blank" v-if="song.youtube" ></a>
                    </div>
                </div>
                <div class="my-6 flex gap-3"><a href="https://play.google.com/store/apps/details?id=com.nomadlyrics&utm_source=nomadlyrics.com&utm_source_platform=desktop" title="Android ama iOS" class="text-nomad-accent text-md rounded-full bg-nomad-800 px-3 py-2" target="_blank">Play Store</a><a href="https://apps.apple.com/app/apple-store/id1503789749?pt=121219390&ct=NomadLyricsWebsite&mt=8" title="Android ama iOS" class="text-nomad-accent text-md rounded-full bg-nomad-800 px-3 py-2" target="_blank">App Store</a></div>
            </div>
            
        </div>
        <div class="w-full my-3" v-if="song.similarSongs && song.similarSongs.length">
            <h2 class="text-xl font-thin mb-3 text-nomad-200">
                Similar
            </h2>
            <ul class="grid md:grid-cols-2 gap-3">
                <li v-for="songContent in song.similarSongs">
                    <card-song :song="songContent" />
                </li>
            </ul>
        </div>
    </div>
    <Youtube :watching="watchVideo" @open-youtube="openYoutube" :id="song.youtube" v-if="watchVideo" />
    <lyrics-add :song="song" :id="lyricsId" @open-lyrics="addLyrics" @lyrics-edit-id="lyricsEditId"
        v-if="addingLyrics"></lyrics-add>
</template>
<script setup>
defineProps({ song: Object });
</script>

<script>
import CardSong from "@/components/CardSong.vue";
import Featuring from "@/components/Featuring.vue";
import { Link } from "@inertiajs/vue3";

import { mapState, mapActions } from "vuex";

import { defineAsyncComponent } from 'vue'
import SmartPlay from "@/components/SmartPlay.vue";
export default {
    data() {
        return {
            watchVideo: false,
            addingLyrics: false,
            lyricsId: null
        };
    },
    beforeMount() {
        document.title = this.song.name + ' by ' + this.song.artist.name
    },
    methods: {
        ...mapActions("favorites", ["addToFavorites"]),
        ...mapActions("current", ["prepareCurrent"]),
        ...mapActions("notification", ["showNotification"]),
        openYoutube() {
            this.watchVideo = !this.watchVideo;
        },
        async copyLink() {
            navigator.clipboard.writeText("https://nomadlyrics.com/song/" + this.song.id);
            this.showNotification({
                message: 'link coppied!',
                type: 'info',
            })
            trackShare('Link', 'song', this.song.id)
        },
        addLyrics() {
            this.addingLyrics = !this.addingLyrics;
            this.$gtag.event("select_content", {
                content_type: "LYRICS::INIT",
                content_id: this.song.id
            });
        },
        lyricsEditId(id) {
            this.lyricsId = id;
        },
        trackShare(platform, type, id) {
            // Track the "SongPlay" event with the associated ID
            this.$gtag.event('share', {
                method: platform, //Facebook, Twitter, etc
                content_type: type, // image, song, event, episode
                song_id: id,
            });

        }

    },
    components: {
        "card-song": CardSong,
        featuring: Featuring,
        Link,
        Promo: defineAsyncComponent(() => import('@/components/Promo')),
        'Youtube': defineAsyncComponent(() => import('@/components/Youtube.vue')),
        'adsense': defineAsyncComponent(() => import("@/components/Adsense.vue")),
        'lyrics-add': defineAsyncComponent(() => import('@/components/LyricsAdd.vue')),
        'adsense-responsive': defineAsyncComponent(() => import('@/components/AdsenseResponsive.vue')),
    },
    computed: {
        imageCover() {
            if (this.song.cover) return this.song.cover;
            return this.song.artist.image;
        },
    },
};
</script>
