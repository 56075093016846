<template>
    <Container class="w-full">
        <div class="w-full flex my-4 gap-3">
            <Link
                href="/backstage"
                class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800"
                >Cancel</Link
            >
        </div>
        <div class="w-full">
            <form @submit.prevent="submit" class="grid gap-4">
                <div>
                    <h6 class="text-nomad-300">Last Updated: {{ topic.updated_at }}</h6>
                </div>
                <div>
                    <TextField
                        placeholder="topic name"
                        v-model:text="form.name"
                    />
                </div>
                <div>
                    <TextField
                        placeholder="topic name arabic"
                        v-model:text="form.name_translations.arabic"
                    />
                </div>
                <div>
                    <TextField
                        placeholder="topic in english"
                        v-model:text="form.name_translations.english"
                    />
                </div>
                <div>
                    <select v-model="form.topic_type" class="w-full text-black rounded-lg">
                        <option disabled>select topic type</option>
                        <option value="hadith">hadith</option>
                        <option value="tafsiir">tafsiir</option>
                        <option value="tajweed">tajweed</option>
                        <option value="fiqh">fiqh</option>
                        <option value="siirah">siirah</option>
                        <option value="quran">quran</option>
                        <option value="lecture">lecture</option>
                        <option value="other">other</option>
                    </select>
                </div>
                <div>
                    <Selector
                        type="scholars"
                        v-model="form.scholar"
                        placeholder="select scholar"
                    />
                </div>
                <div><ImageUploader :image="form.image" v-model="form.image" /></div>
                <div class="block w-full mb-10">
                    <textarea v-model="form.description" class="text-black w-full h-64 rounded-lg" ></textarea>
                </div>
                <div class="block"><button class="text-nomad-900 bg-nomad-accent text-xl rounded-md py-2 px-4">Update</button></div>
            </form>
            <h6 v-if="form.hasErrors" class="text-nomad-red my-2">Errors: {{ form.errors }}</h6>
            <pre class="text-white">{{ form }}</pre>
        </div>
   
    </Container>
</template>
<script setup>
import { defineAsyncComponent } from 'vue'
import Container from "@/Pages/Backstage/Container.vue";
import TextField from "@/Pages/Backstage/Components/TextField.vue";
import ImageUploader from "@/Pages/Backstage/Components/ImageUploader.vue";
import Selector from "@/Pages/Backstage/Components/Selector.vue";
import { useForm, usePage, router, Link } from "@inertiajs/vue3";
import { ref, onMounted } from "vue";
const { topic } = usePage().props;
const form = useForm({
    name: topic.name,
    name_translations: topic.name_translations ?? { arabic: null, english: null },
    topic_type: topic.topic_type,
    scholar: topic.scholar,
    image: topic.image,
    description: topic.description,
});

onMounted(() => {
    console.log(usePage().props);
});

function submit() {
    form.put('/backstage/topics/' + topic.id)
}
</script>
